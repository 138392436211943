import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '../../../axios/axiosInstance';

interface TrackUserOpenPaymentModal {
  updatedModalCounter: boolean;
  clickCount: number;
}
const key = ['TRACK_USER_OPEN_PAYMENT_MODAL'];
export const useTrackUserOpenPaymentModal = () =>
  useMutation({
    mutationKey: [...key],
    mutationFn: async (): Promise<TrackUserOpenPaymentModal> => {
      const { data } = await axiosInstance.post('api/users/payment-modal-activity');
      return data;
    }
  });
