import { globalHistory } from '@reach/router';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { userData as userRecoil } from '../../recoil/atoms/userDataAtom';
import { useShowPopUnder } from '../../V2/services/popunder/useShowPopUnder';
import { appendAdZones, removeAdZones, setShowPopupState } from './adZoneV2';
import {
  executePopUnder,
  getPopUnderExpiration,
  removePopUnderOverlay,
  updatePopUnderHref
} from './helpers';

const usePopUnder = (isPopUnderEnabled) => {
  const [userData] = useRecoilState(userRecoil);
  const { refetch } = useShowPopUnder();

  useEffect(() => {
    if (userData?.data) {
      updatePopUnderHref();
    }
  });

  useEffect(() => {
    const shouldShowPopUnder = async () => {
      const { data } = await refetch();
      setShowPopupState(data?.showPopUnder);

      return data?.showPopUnder;
    };

    const shouldDisplayPopUnder = () => {
      if (!isPopUnderEnabled) {
        return false;
      }

      const popUnderExpirationValue = getPopUnderExpiration();
      const popUnderExpirationDate = popUnderExpirationValue
        ? new Date(parseInt(popUnderExpirationValue))
        : null;

      return !popUnderExpirationDate || new Date() >= popUnderExpirationDate;
    };

    const handleShowPopUnder = () => {
      if (window.location.pathname !== '/members-area' && !shouldDisplayPopUnder()) {
        removePopUnderOverlay();
        removeAdZones();
        return;
      }

      shouldShowPopUnder().then((showPopUnder) => {
        if (showPopUnder) {
          shouldDisplayPopUnder() ? executePopUnder() : removePopUnderOverlay();

          if (window.location.pathname === '/members-area') {
            appendAdZones();
          }
        } else {
          removePopUnderOverlay();
          removeAdZones();
        }
      });
    };

    const initPopUnder = async () => {
      try {
        handleShowPopUnder();
      } catch (error) {
        console.error('Error initializing pop under:', error);
      }
    };

    if (userData?.data) {
      initPopUnder();
      updatePopUnderHref();
    }

    const triggerPopUnder = globalHistory.listen(({ location }) => {
      if (location.pathname !== '/members-area') {
        removeAdZones();
      }

      if (userData?.data) {
        initPopUnder();
        updatePopUnderHref();
      }
    });

    return () => {
      triggerPopUnder();
    };
  }, [userData?.data, refetch]);

  return null;
};

export default usePopUnder;
