// Tracks whether the ad zones are already loaded
let areAdZonesLoaded = false;
let showPopunder = false;

/**
 * Appends the ad zone iframe to the body for the specified ad type (header or footer).
 * @param {string} adType - The type of ad zone ("header" or "footer").
 * @param {string} containerSelector - The selector of the container element.
 * @param {string} smallScreenSrc - The iframe source URL for small screens.
 * @param {string} largeScreenSrc - The iframe source URL for large screens.
 * @param {object} dimensions - Object containing width and height for small and large screens.
 */
const appendAdZone = (adType, containerSelector, smallScreenSrc, largeScreenSrc, dimensions) => {
  const adZoneId = `${adType}-adzone`;
  const existingIframe = document.getElementById(adZoneId);

  // Prevent duplicate loading
  if (existingIframe) {
    return;
  }

  const isSmallScreen = window.innerWidth <= 850;
  const iframeSrc = isSmallScreen ? smallScreenSrc : largeScreenSrc;
  const { smallWidth, smallHeight, largeWidth, largeHeight } = dimensions;

  const iframe = createAdZoneIframe(
    adZoneId,
    iframeSrc,
    isSmallScreen ? smallWidth : largeWidth,
    isSmallScreen ? smallHeight : largeHeight
  );

  document.body.appendChild(iframe);
  moveAdZoneToContainer(`#${adZoneId}`, containerSelector);
};

/**
 * Creates the ad zone iframe with the given parameters.
 * @param {string} id - The ID of the iframe element.
 * @param {string} src - The source URL for the iframe.
 * @param {number} width - The width of the iframe.
 * @param {number} height - The height of the iframe.
 * @returns {HTMLElement} The created iframe element.
 */
const createAdZoneIframe = (id, src, width, height) => {
  const iframe = document.createElement('iframe');
  iframe.id = id;
  iframe.src = src;
  iframe.setAttribute('data-zone', id.includes('header') ? '1035' : '1036');
  iframe.setAttribute('sandbox', 'allow-popups allow-scripts allow-same-origin allow-forms');
  iframe.style.cssText = 'border: 0; display: block;';
  iframe.width = width;
  iframe.height = height;
  iframe.scrolling = 'no';
  iframe.frameBorder = '0';
  return iframe;
};

/**
 * Moves the ad zone iframe to the designated container.
 * @param {string} iframeSelector - The selector of the iframe element.
 * @param {string} containerSelector - The selector of the container element.
 */
const moveAdZoneToContainer = (iframeSelector, containerSelector) => {
  const iframeEl = document.querySelector(iframeSelector);
  const container = document.querySelector(containerSelector);

  if (iframeEl && container) {
    iframeEl.remove(); // Remove the iframe from its current parent
    container.appendChild(iframeEl); // Append iframe to the new container
  }
};

export const setShowPopupState = (state) => {
  showPopunder = state;
};

/**
 * Appends header and footer ad zones based on screen size.
 */
export const appendAdZones = () => {
  if (window?.location?.pathname !== '/members-area' || areAdZonesLoaded || !showPopunder) {
    return; // Prevent re-adding ad zones
  }

  appendAdZone(
    'header',
    '#header-adzone-container',
    '//a.7amz.com/loader?a=1035&s=135&t=22&p=242&s1=SITE_ID',
    '//a.7amz.com/loader?a=1037&s=135&t=20&p=242&s1=SITE_ID',
    {
      smallWidth: 300,
      smallHeight: 100,
      largeWidth: 728,
      largeHeight: 90
    }
  );

  appendAdZone(
    'footer',
    '#footer-adzone-container',
    '//a.7amz.com/loader?a=1036&s=135&t=21&p=242&s1=SITE_ID',
    '//a.7amz.com/loader?a=1038&s=135&t=20&p=242&s1=SITE_ID',
    {
      smallWidth: 300,
      smallHeight: 250,
      largeWidth: 728,
      largeHeight: 90
    }
  );

  areAdZonesLoaded = true; // Mark ad zones as loaded
};

/**
 * Removes all ad zones from the page.
 */
export const removeAdZones = () => {
  ['header-adzone', 'footer-adzone'].forEach((id) => {
    const adZone = document.getElementById(id);
    if (adZone) {
      adZone.remove();
    }
  });

  areAdZonesLoaded = false; // Reset the loaded state
};

// Attach event listeners to handle page load and resize events.
window.addEventListener('load', appendAdZones);
window.addEventListener('resize', () => {
  removeAdZones();
  appendAdZones();
});
