import './inboxConversation.scss';

import { useNavigate, useParams } from '@reach/router';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsInbox } from 'react-icons/bs';
import { useRecoilState, useRecoilValue } from 'recoil';

import { queryClient } from '../../../../../../../../App';
import { useGetProfile } from '../../../../../../../../core/APIHooks/useGetProfile';
import { useGetConversationById } from '../../../../../../../../core/messages/useGetConversationById';
import { key as InboxKey } from '../../../../../../../../core/messages/useGetInbox';
import { key as LatestKey } from '../../../../../../../../core/messages/useGetLatestMessages';
import { useReadMessage } from '../../../../../../../../core/messages/useReadMessage';
import useCheckMobileScreen from '../../../../../../../../hooks/useCheckMobileScreen';
import { failedMessages } from '../../../../../../../../recoil/atoms/failedMessagesAtom';
import { userData } from '../../../../../../../../recoil/atoms/userDataAtom';
import { userProfile } from '../../../../../../../../recoil/selectors/userProfile';
import { GiftDropDown } from '../../../../../../../../V2/components/shared/gift-dropdown/GiftDropDown';
import { CreditsModal } from '../../../../../../../../V2/components/V1import/CreditsModal/CreditsModal';
import { ViewFromEnum } from '../../../../../../../../V2/model/enum/ViewFromEnum.enum';
import { MEMBERS_ROUTES } from '../../../../members_routes';
import ChatStarters from '../../../MessagesNew/Conversation/ChatStarters/ChatStarters';
import { HeaderSkeleton } from '../skeletons/HeaderSkeleton';
import { ConversationHeader } from './ConversationHeader';
import { Description } from './Description';
import { MessageArea } from './MessageArea';
import { MessageExchangeList } from './MessageExchangeList';

const hasUnreadMessages = (pages, profileId) => {
  return pages
    ?.map((item) => item.data)
    .flat()
    .find((item) => item.profile_id.toString() === profileId)?.unread_messages;
};

export const InboxConversation = () => {
  const skeletonInputsNo = 5;
  const user = useRecoilValue(userProfile);
  const params = useParams();
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const [data] = useRecoilState(userData);
  const { mutate: read } = useReadMessage();
  const searchParams = new URLSearchParams(location.search);
  const viewFromParam = searchParams.get('viewFrom');
  const view_from = viewFromParam === ViewFromEnum.EMAIL ? ViewFromEnum.EMAIL : ViewFromEnum.INBOX;

  const chats = queryClient.getQueryData(InboxKey);
  const conversations = queryClient.getQueryData(LatestKey);
  const hasUnread = hasUnreadMessages(chats?.pages, params?.id);
  const hasUnreadConversationChat = hasUnreadMessages(conversations?.pages, params?.id);

  useEffect(() => {
    if (user?.id && (hasUnread || hasUnreadConversationChat)) {
      read({ userId: user.id, id: params?.id, view_from });
    }
  }, [hasUnread, hasUnreadConversationChat, params?.id, read, user, view_from]);

  const [showDescription, setShowDescription] = useState(isMobile);
  const [starter, setStarter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { data: profile, isLoading: loadingProfile } = useGetProfile(params?.id);
  const {
    data: messages,
    hasNextPage,
    fetchNextPage,
    isInitialLoading,
    isFetchingNextPage,
    isError: errorMessages
  } = useGetConversationById(data.data?.profile?.id, params?.id);
  const lastMessageId = messages?.pages?.at(-1)?.data?.at(0)?.id ?? null;
  const profileId = params?.id ? Number(params?.id) : null;
  const [failed] = useRecoilState(failedMessages);
  const back = () => {
    navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/`);
  };
  useEffect(() => {
    setStarter(undefined);
  }, [params.id]);
  const isSkeleton = () => {
    const skeleton = [];
    for (let i = 0; i < skeletonInputsNo; i++) {
      skeleton.push(
        <div className={'skeleton-item'} key={`skeleton_message_${i}`}>
          <Skeleton.Input size={'large'} active={!errorMessages} />
        </div>
      );
    }
    return skeleton;
  };
  const onClick = () => setShowDescription((prevState) => !prevState);
  const modalsOpenClose = () => {
    setModalOpen(false);
  };
  let content;
  if (!isInitialLoading && messages) {
    content = (
      <MessageExchangeList
        loading={hasNextPage && isFetchingNextPage}
        hasPages={hasNextPage}
        loadPages={fetchNextPage}
        firstOpen={isInitialLoading}
        messages={messages.pages.map((page) => page.data).flat()}
        failedMessages={failed[params.id] ?? []}
        profile={params.id}
        convId={params?.conversationId}
        openBuy={() => setModalOpen(true)}
      />
    );
  } else if (errorMessages) {
    content = (
      <div className={'inbox-empty'}>
        <h2>No conversation loaded</h2>
        <BsInbox size={60} color={'rgba(0,0,0,0.2)'} />
      </div>
    );
  } else {
    content = <div className={'messages-skeleton'}>{isSkeleton()}</div>;
  }

  return (
    <div className={'conversation'}>
      {((isMobile && showDescription) || !isMobile) && (
        <div className={'chat'}>
          <div className={'conv-header'}>
            {profile ? (
              <ConversationHeader
                profile={profile}
                isMobile={isMobile}
                onBack={back}
                onClick={onClick}
              />
            ) : (
              <HeaderSkeleton active={loadingProfile} />
            )}
          </div>
          <ChatStarters getStarter={setStarter} starter={starter} />
          <GiftDropDown name={profile?.username} profileId={profile?.id} />
          {content}
          <MessageArea
            openBuy={() => setModalOpen(true)}
            isMobile={isMobile}
            profileId={params?.id}
            key={params?.id}
            convId={params?.conversationId}
            starter={starter}
            disableSendButton={!user?.id}
          />
          <CreditsModal
            isModalOpen={modalOpen}
            closeModal={() => modalsOpenClose()}
            profileId={profileId}
            lastMessageId={lastMessageId}
          />
        </div>
      )}
      {((isMobile && !showDescription) || !isMobile) && (
        <Description
          profile={profile}
          isMobile={isMobile}
          onClick={onClick}
          active={loadingProfile}
        />
      )}
    </div>
  );
};
