/* eslint-disable react/no-unescaped-entities */
import './NonConsensualParticipationPolicy.scss';

import React from 'react';

import HEADERIMAGE from '../../../../../assets/images/HeadersImages/TermsAndCond-Header.png';
import HeaderLayout from '../../../../../components/HeaderLayout/HeaderLayout';

const NonConsensualParticipationPolicy = () => {
  const title = 'Non-Consensual Participation Policy';
  const description = 'Effective Date: Dec 11, 2024';
  const image = HEADERIMAGE;
  return (
    <div className="Non-Consensual-Participation">
      <HeaderLayout title={title} description={description} image={image} />

      <div className="Non-Consensual-Participation-Container">
        <p>Effective Date: Dec 11, 2024</p>
        <h3>1. Introduction:</h3>
        <p>
          LocalFlirt.com (https://localflirt.com/) is committed to fostering a safe, respectful, and
          trustworthy online community. This policy underscores our dedication to preventing
          non-consensual participation and ensuring all user interactions are based on freely given,
          informed consent. This policy applies to all users of LocalFlirt.com
          (https://localflirt.com/). By using the platform, you agree to this policy.
        </p>
        <h3>2. Definition of Non-Consensual Participation:</h3>
        <p>
          Non-consensual participation encompasses any activity on LocalFlirt.com
          (https://localflirt.com/) where a user engages without having provided explicit and
          informed consent. This includes:
        </p>
        <p>
          <strong>Account Creation:</strong> Creating an account without explicitly agreeing to the
          Terms and Conditions and this Non-Consensual Participation Policy.<br></br>
          <strong>Content Uploads:</strong> Uploading photos, videos, or other content without
          providing consent for its use and display on the platform.<br></br>
          <strong>User Interaction:</strong> Initiating contact with or engaging other users without
          their clear and willing consent. <br></br>
          <strong>Data Collection:</strong> Any collection of a user's data without their explicit
          consent.
        </p>
        <h3>3. Informed Consent Requirement:</h3>
        <p>
          LocalFlirt.com (https://localflirt.com/) requires all users to provide explicit, informed
          consent before participating in any activity on the platform. This means:
        </p>
        <ul>
          <li>Users must fully understand what they are consenting to.</li>
          <li>Consent must be freely given, without coercion or pressure.</li>
          <li>Consent is not implied; it must be clearly and actively expressed.</li>
        </ul>
        <p>This consent is necessary for:</p>
        <ul>
          <li>Creating a user account.</li>
          <li>Uploading or sharing content (photos, videos, messages, etc.).</li>
          <li>Initiating contact with other users.</li>
        </ul>
        <h3>4. Documentation and Retention of Consent:</h3>
        <p>
          LocalFlirt.com maintains detailed records of user consent for a minimum of three (3)
          years. These records demonstrate:
        </p>
        <ul>
          <li>Affirmative consent to the Terms and Conditions.</li>
          <li>Affirmative consent to this Non-Consensual Participation Policy.</li>
          <li>
            Specific consent for content uploads, if applicable (e.g., consent for a particular
            photo to be displayed).
          </li>
          <li>Any other explicit consent given for specific platform activities.</li>
        </ul>
        <h3>5. Obtaining Consent during Registration:</h3>
        <p>
          The registration process at https://localflirt.com/ requires users to actively provide
          consent. This includes:
        </p>
        <ul>
          <li>
            A clear and unambiguous statement requiring affirmative consent (e.g., checking a box,
            clicking an "I agree" button).
          </li>
          <li>Links to our Terms and Conditions and this Non-Consensual Participation Policy.</li>
          <li>A clear explanation of the implications of providing consent.</li>
        </ul>
        <h3>6. Monitoring for Compliance:</h3>
        <p>
          LocalFlirt.com actively monitors user activity to detect and address any instances of
          non-consensual participation. We use a combination of automated tools and manual reviews
          to identify potential violations.
        </p>
        <h3>7. Enforcement and Corrective Measures:</h3>
        <p>
          Any user found to be participating on the platform without valid consent or violating
          these terms will face corrective action, including:
        </p>
        <ul>
          <li>Warnings</li>
          <li>Temporary suspension of account privileges</li>
          <li>Permanent termination of account</li>
        </ul>
        <h3>8. User Responsibility:</h3>
        <p>
          Users are responsible for ensuring they have the necessary rights to any content they
          upload and that their actions comply with this policy and all applicable laws. Users
          should be mindful of the implications of their actions and content shared on the platform.
        </p>
        <h3>9. Policy Review and Updates:</h3>
        <p>
          This Non-Consensual Participation Policy undergoes regular review (at least annually) to
          ensure it remains compliant with applicable laws, best practices, and user feedback. Any
          updates will be posted on the LocalFlirt.com website.
        </p>
        <h3>10. Conclusion:</h3>
        <p>
          LocalFlirt.com is committed to creating a safe, respectful, and inclusive online
          environment where all user participation is based on freely given consent.
        </p>
      </div>
    </div>
  );
};

export default NonConsensualParticipationPolicy;
