import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useParams } from '@reach/router';
import { notification } from 'antd';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { VscRefresh } from 'react-icons/vsc';
import { useRecoilState } from 'recoil';

import { CanvasImage } from '../../../../../../../../components/CanvasImage/CanvasImage';
import { useSendMessage } from '../../../../../../../../core/messages/useSendMessage';
import { failedMessages } from '../../../../../../../../recoil/atoms/failedMessagesAtom';
import { userData } from '../../../../../../../../recoil/atoms/userDataAtom';
import { useTrackUserOpenPaymentModal } from '../../../../../../../../V2/services/analytics/useTrackUserOpenPaymentModal';

export const MessageBubble = forwardRef(
  (
    { body, time, attachment, sender, failed, openBuy, messageId, loading, queue, is_gifting },
    ref
  ) => {
    const { conversationId, id } = useParams();
    const { mutate: send, isLoading } = useSendMessage();
    const [data] = useRecoilState(userData);
    const [failedMsgs, setFailedMsgs] = useRecoilState(failedMessages);
    const { mutate: trackUserOpenPaymentModal } = useTrackUserOpenPaymentModal();

    const removeFailedMessage = () => {
      const newFailed = [...failedMsgs[id]];
      const index = newFailed.findIndex((item) => item.id === messageId);
      newFailed.splice(index, 1);
      setFailedMsgs({ ...failedMsgs, [id]: newFailed });
    };

    const handleReuploadError = (e) => {
      if (e.response.data.errors?.image?.length) {
        removeFailedMessage();
        notification.error({ message: e.response.data.errors.image[0] });
      }
    };

    return (
      <>
        <div
          className={`bubble ${is_gifting ? 'system-message' : sender ? 'other' : 'me'} ${
            failed ? 'failed' : ''
          }`}
          ref={ref}
        >
          {failed && (
            <div className={'bubble-resend'}>
              <VscRefresh
                onClick={() =>
                  send(
                    {
                      profileId: id,
                      messageText: body,
                      sendImage: attachment,
                      chatId: conversationId
                    },
                    {
                      onSuccess: () => removeFailedMessage(),
                      onError: (e) => handleReuploadError(e)
                    }
                  )
                }
              />
            </div>
          )}
          {isLoading && (
            <div className={'bubble-resend'}>
              <SyncOutlined spin />
            </div>
          )}
          {loading && (
            <div className={'bubble-resend'}>
              <LoadingOutlined />
            </div>
          )}
          {failed && data?.data?.credits === 0 && (
            <div
              className={'bubble-buy-credits'}
              onClick={() => {
                openBuy();
                trackUserOpenPaymentModal();
              }}
            >
              Buy credits
            </div>
          )}
          {attachment &&
            (queue ? (
              <img src={attachment} alt={'sending image'} />
            ) : (
              <CanvasImage
                src={failed ? URL.createObjectURL(attachment) : attachment}
                previewSrc={failed ? URL.createObjectURL(attachment) : attachment}
                enablePreview
              />
              /*<ImageNPreview
                name={failed ? URL.createObjectURL(attachment) : attachment}
                nameFullSize={failed ? URL.createObjectURL(attachment) : attachment}
              />*/
            ))}
          <div className={'message-body'} dangerouslySetInnerHTML={{ __html: body ?? null }} />
          {!is_gifting && (
            <div className={'message-time'}>{moment(new Date(time)).format('HH:mm')}</div>
          )}
        </div>
      </>
    );
  }
);
