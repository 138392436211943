import { useEffect, useState } from 'react';

export const useDeviceType = () => {
  const [device, setDevice] = useState('desktop');

  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDevice('mobile');
      } else if (width <= 1024) {
        setDevice('tablet');
      } else {
        setDevice('desktop');
      }
    };

    const mediaQuery = window.matchMedia('(max-width: 1024px)');
    updateDeviceType();
    mediaQuery.addEventListener('change', updateDeviceType);

    return () => mediaQuery.removeEventListener('change', updateDeviceType);
  }, []);

  return device;
};
