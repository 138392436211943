import { navigate } from '@reach/router';

import { MEMBERS_ROUTES } from './members_routes.js';

// Navigates to root page
export const showMembersRootPage = () => {
  return navigate(MEMBERS_ROUTES.MEMBERSAREA);
};

// Navigates to messages page
export const showMessagesPage = (profileId) => {
  return profileId
    ? navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/${profileId}`)
    : navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}`);
};
/* export const showMessagesPage = (profileId) => {
  return profileId
    ? navigate(
        `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGES}/${profileId}`
      )
    : navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGES}`);
}; */

// Navigates to member profile page
export const showMemberProfilePage = (userId) => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERPROFILE}/${userId}`);
};

//Navigates to browse profiles page
export const browseProfilesPage = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}`);
};

//Navigates to match game page
export const matchGame = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MATCHGAME}`);
};

//Navigate to my matches page
export const myMatches = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYMATCHES}`);
};

//Navigate to my favorites page
export const myFavorites = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYFAVORITES}`);
};

//Navigate to visitors page
export const visitors = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.VISITORS}`);
};

//Navigate to credits page
export const credits = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CREDITS}`);
};

//Navigate to my profile page
export const myProfile = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYPROFILE}`);
};

//Navigate to members pricing page
export const membersPricing = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERSPRICING}`);
};

//Navigate to payment page
export const payment = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PAYMENT}`);
};

//Navigate to about us page
export const aboutUs = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.ABOUT}`);
};

//Navigate to FAQ page
export const fAQ = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.FAQ}`);
};

//Navigate to pricing page
export const pricing = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PRICING}`);
};

//Navigate to contact page
export const contact = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.CONTACTUS}`);
};

//Navigates to terms and conditions page
export const terms = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.TERMS}`);
};

//Navigate to privacy policy page
export const privacy = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PRIVACY}`);
};

//Navigate to anti human traficking page
export const antiHumanTraficking = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.ANTIHUMANTRAFICKING}`);
};

//Navigate to non consensual participation page
export const nonConsensualParticipationPolicy = () => {
  return navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.NONCONSENSUALPARTICIPATION}`);
};
