import './BasicHeader.scss';

import { useLocation } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';

import VeriSignLogo from '../../assets/images/HomePage/veri-sign-logo.png';
import Close from '../../assets/images/Modal/Close.png';
import Button from '../Button/Button';
import LogInFlow from '../LogInFlow/LogInFlow';
import LogoComponent from '../LogoComponent/LogoComponent';
import SignUpFlow from '../SignUpFlow/SignUpFlow';

const BasicHeader = () => {
  const registerModalRef = useRef(null);
  const loginModalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const openModal = (ref) => {
    ref.current.openModal();
  };

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    const scrollDiv = document.querySelector('.MainView');
    const offset = scrollDiv.scrollTop;
    if (offset >= 111) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 200) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    const scrollDiv = document.querySelector('.MainView');
    scrollDiv.addEventListener('scroll', changeBackground);
    return () => {
      scrollDiv.removeEventListener('scroll', changeBackground);
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={navbar ? 'navbar active  BasicHeader  ' : 'navbar BasicHeader '}>
      <div className="SignUp-Logo-Wrapper Container">
        <div className="ButtonLogo-Wrapper">
          <LogoComponent />
          {/* <div className="Btn-VeriSignLogo"> */}
          <Button
            className="SignUpButton"
            text="Sign up"
            onClick={openModal.bind(null, registerModalRef)}
          />

          {/* </div> */}
        </div>

        <div className="MobileNav">
          <LogoComponent />
          {!location.pathname.includes('email-verification-instruction') && (
            <>
              <div onClick={() => setOpen(!open)} className={open ? 'noHamburger' : 'Hamburger'}>
                <div className="Burger"></div>
                <div className="Burger"></div>
                <div className="Burger"></div>
              </div>
              {open && (
                <div className="ButtonLogo-Wrapper-Mobile">
                  <div className="Logo-CloseBtn">
                    <LogoComponent />
                    <img
                      src={Close}
                      alt=""
                      className="CloseBtn"
                      onClick={() => setOpen(false)}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className="SignUp-VeriSign">
                    <Button text="Log in" onClick={openModal.bind(null, loginModalRef)} />
                    <Button text="Sign up" onClick={openModal.bind(null, registerModalRef)} />
                  </div>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    className="VeriSign-Logo"
                    src={VeriSignLogo}
                    alt=""
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <SignUpFlow modalRef={registerModalRef} />
      <LogInFlow modalRef={loginModalRef} />
    </div>
  );
};

export default BasicHeader;
