import './Settings.scss';

import { CreditCardOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, notification } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';

import TIP from '../../../../../../../assets/images/MembersArea/amber.jpg';
import Button from '../../../../../../../components/Button/Button';
import { Form } from '../../../../../../../components/Form/Form';
import Input from '../../../../../../../components/Form/Input/Input';
import LoaderComponent from '../../../../../../../components/LoaderCompoment/LoaderComponent';
import { useLogout } from '../../../../../../../hooks/useLogout';
import { userData as userRecoil } from '../../../../../../../recoil/atoms/userDataAtom';
import { getToken } from '../../../../../../../services/token-service/token.service';
import { useRemoveCreditCard } from '../../../../../../../V2/services/payment/useRemoveCreditCard';
import ProfileNav from '../ProfileNav/ProfileNav';

const schema = yup.object().shape({
  currentPassword: yup.string().required('Current Password is required!'),
  newPassword: yup.string(),
  repeatNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  currentEmail: yup.string().email('Invalid email format').required('Current Email is required!'),
  newEmail: yup.string().email('Invalid email format'),
  repeatNewEmail: yup
    .string()
    .email('Invalid email format')
    .oneOf([yup.ref('newEmail'), null], 'Email must match')
});

const renderLoader = () => {
  return (
    <div className="Loading">
      <LoaderComponent />
    </div>
  );
};

const Settings = () => {
  const { mutate: removeCreditCard, isLoading: removingCard } = useRemoveCreditCard();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const logout = useLogout();
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState([]);
  const [user] = useRecoilState(userRecoil);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const onSubmit = ({
    currentPassword,
    newPassword,
    repeatNewPassword,
    currentEmail,
    newEmail,
    repeatNewEmail
  }) => {
    if (currentEmail && newEmail && repeatNewEmail && currentPassword) {
      setLoading(true);
      axios
        .post('/api/users/update-email', {
          _method: 'PATCH',
          current_email: userData.email,
          new_email: newEmail,
          new_email_confirmation: repeatNewEmail,
          password: currentPassword
        })
        .then((res) => {
          notification.success({
            message: 'Email Successfully Updated.',
            description:
              res?.data[0] ??
              res?.data?.message ??
              'A verification email has been sent to the new email address. Please confirm it in order to regain access to your Account.',
            duration: 5
          });
          setLoading(false);
          reset();
        })
        .catch((err) => {
          setLoading(false);
          notification.error({
            message:
              (err?.data?.errors?.new_email?.[0] || err?.data?.message) ??
              'Something went wrong. Please try again.'
          });
        });
    }
    if (currentPassword && newPassword && repeatNewPassword) {
      setLoading(true);
      axios
        .post(`/api/users/update-password`, {
          _method: 'PATCH',
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: repeatNewPassword
        })
        .then(() => {
          setLoading(false);
          reset();
          notification.success({
            message: 'Password Successfully Updated.'
          });
        })
        .catch((err) => {
          notification.error({
            message: err.data.message
          });
          setLoading(false);
        });
    }
  };
  const [modal, contextHolder] = Modal.useModal();
  const confirmRemoveCard = () => {
    modal.confirm({
      title: 'Confirm removing credit card',
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          Are you sure you want to remove existing payment details?
          <br />
          You can enter new card details next time you pay.
        </p>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      okButtonProps: { className: 'confirm-credit-card-ok' },
      onOk: () => removeCreditCard()
    });
  };
  useEffect(() => {
    const accessToken = getToken();
    setLoading(true);
    axios
      .get(`/api/users/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        /*** set recoil state here***/
        setUserData(data.data.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
    // return () => accessToken;
  }, []);

  return (
    <div className="settings-wrapper">
      {contextHolder}
      <ProfileNav />
      {loading ? (
        renderLoader()
      ) : error ? (
        <h2 style={{ color: 'red', textAlign: 'center' }}>{error}!</h2>
      ) : (
        <Form className="form" onSubmit={handleSubmit(onSubmit)}>
          {userData.email && setValue('currentEmail', userData?.email)}
          <div className="form-input">
            <div className="column">
              <h3 className="title">Password</h3>
              <div className="input-wrapper">
                <label>Current Password</label>
                <Input
                  onChange={() =>
                    setErrorMessage((prevState) => ({
                      ...prevState,
                      current_password: '',
                      password: ''
                    }))
                  }
                  register={register}
                  name="currentPassword"
                  type="password"
                  errorMessage={errors?.currentPassword?.message}
                  additionalError={
                    (errorMessage.current_password && errorMessage.current_password.join(' ')) ||
                    (errorMessage.password && errorMessage.password.join(' '))
                  }
                />
              </div>
              <div className="input-wrapper">
                <label>New Password</label>
                <Input
                  register={register}
                  name="newPassword"
                  type="password"
                  errorMessage={errors?.newPassword?.message}
                />
              </div>
              <div className="input-wrapper">
                <label>Repeat New Password</label>
                <Input
                  register={register}
                  name="repeatNewPassword"
                  type="password"
                  errorMessage={errors?.repeatNewPassword?.message}
                />
              </div>
            </div>
            <div className="column">
              <h3 className="title">Email Address</h3>
              <div className="input-wrapper">
                <label>Current Email</label>
                <Input
                  register={register}
                  value={userData?.email}
                  name="currentEmail"
                  errorMessage={errors?.currentEmail?.message}
                  type="text"
                />
              </div>
              <div className="input-wrapper">
                <label>New Email</label>
                <Input
                  onChange={() =>
                    setErrorMessage((prevState) => ({
                      ...prevState,
                      new_email: ''
                    }))
                  }
                  register={register}
                  name="newEmail"
                  errorMessage={errors?.newEmail?.message}
                  additionalError={errorMessage.new_email && errorMessage.new_email.join(' ')}
                  type="text"
                />
              </div>
              <div className="input-wrapper">
                <label>Repeat New Email</label>
                <Input
                  register={register}
                  name="repeatNewEmail"
                  errorMessage={errors?.repeatNewEmail?.message}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="btn-box">
            <button className="btn" type="submit">
              save changes
            </button>
          </div>
        </Form>
      )}
      <div className={'form-input'}>
        <div className={'column'}>
          <h3 className="title">Billing</h3>
          <div>
            <Button
              icon={<CreditCardOutlined />}
              onClick={confirmRemoveCard}
              loading={removingCard}
              disabled={!user?.data?.has_spent_before || user?.data?.user_has_card_removed}
            >
              Remove stored credit card
            </Button>
          </div>
        </div>
        <div className={'column'}>
          <div className="image-box">
            <img onContextMenu={(e) => e.preventDefault()} src={TIP} alt="tip" />
          </div>
          <div className="text-box">
            <p className="ProfileSettings-Tip">
              Amber’s Tip: Keep your account safe by using a strong and unique password that you
              don’t use on any other online accounts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
