/* eslint-disable react-hooks/exhaustive-deps */
import './Profile.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Switch, Tooltip } from 'antd';
import {
  format,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  setDate,
  setMonth,
  setYear,
  sub
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';

import TIP from '../../../../../../../assets/images/MembersArea/amber.jpg';
import Dropdown from '../../../../../../../components/Form/Dropdown/Dropdown';
import Input from '../../../../../../../components/Form/Input/Input';
import LoaderComponent from '../../../../../../../components/LoaderCompoment/LoaderComponent';
import SuccessMessage from '../../../../../../../components/SuccessMessage/SuccessMessage';
import { useSaveUserProfile } from '../../../../../../../core/APIHooks/useSaveUserProfile';
import { setProfile } from '../../../../../../../redux/actions/profile';
import { getCityZipCode } from '../../../../../../../services/city-service/city.service';
import {
  getUserData,
  getUserProfile
} from '../../../../../../../services/user-service/user.service';
import { parseError } from '../../../../../../../util/parsers';
import ProfileNav from '../ProfileNav/ProfileNav';
import { values } from './data/height';

const schema = yup.object().shape({
  zip_code: yup.string().test('len', 'Must be exactly 5 characters', (val) => val.length === 5)
});

const Profile = () => {
  const userData = getUserData();
  const { mutate } = useSaveUserProfile();
  const [genders, setGenders] = useState([
    { id: 1, gender: 'I am a Man', value: 'male' },
    { id: 2, gender: 'I am a Woman', value: 'female' }
  ]);
  const [lookingFor, setLookingFor] = useState([
    { id: 1, seeking: 'Looking for a Man', value: 'men' },
    { id: 2, seeking: 'Looking for a Woman', value: 'women' }
  ]);
  const [bodyTypes, setBodyTypes] = useState([
    { id: 1, bodyType: 'Lean', value: 'lean' },
    { id: 2, bodyType: 'Athletic', value: 'athletic' },
    { id: 3, bodyType: 'Normal', value: 'normal' },
    { id: 4, bodyType: 'Big', value: 'big' }
  ]);
  const [civilStatus, setCivilStatus] = useState([
    { id: 1, status: 'Single', value: 'single' },
    { id: 2, status: 'Divorced', value: 'divorced' },
    { id: 3, status: 'Married', value: 'married' },
    { id: 4, status: 'In a Relationship', value: 'in a relationship' }
  ]);
  const [hairColors, setHairColors] = useState([
    { id: 1, hair: 'Blonde', value: 'blonde' },
    { id: 2, hair: 'Red', value: 'red' },
    { id: 3, hair: 'Brown', value: 'brown' },
    { id: 4, hair: 'Black', value: 'black' },
    { id: 5, hair: 'White/Gray', value: 'white/gray' }
  ]);
  const [eyeColors, setEyeColors] = useState([
    { id: 1, eyes: 'Blue', value: 'blue' },
    { id: 2, eyes: 'Brown', value: 'brown' },
    { id: 3, eyes: 'Hazel', value: 'hazel' },
    { id: 4, eyes: 'Green', value: 'green' },
    { id: 5, eyes: 'Black', value: 'black' }
  ]);
  const [interests, setInterests] = useState([
    { id: 1, label: 'Kissing', value: 'kissing' },
    { id: 2, label: 'Exchanging Pictures', value: 'exchanging pictures' },
    { id: 3, label: 'Safe Sex', value: 'safe sex' },
    { id: 4, label: 'Erotic Massage', value: 'erotic massage' },
    { id: 5, label: 'Lingerie', value: 'lingerie' },
    { id: 6, label: 'Oral Sex', value: 'oral sex' },
    { id: 7, label: 'Threesome', value: 'threesome' },
    { id: 8, label: 'Anal Sex', value: 'anal sex' },
    { id: 9, label: 'Public Sex', value: 'public sex' }
  ]);

  const [birthday, setBirthday] = useState('');
  const currentUserProfile = useSelector((state) => state.profile);
  const [zipcodeCities, setZipcodeCities] = useState([]);
  const dispatch = useDispatch();
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const toggleOnlineStatus = (e) => {
    setValue('show_online_status', Boolean(e));
  };
  const togglePrivateLocation = (e) => {
    setValue('private_location', Boolean(e));
  };

  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  const heightNumbers = values.map((item, index) => {
    const heightValues = item.split('.');

    return {
      id: index,
      value: item,
      height: `${heightValues[0]}'${heightValues[1]}"`
    };
  });

  const daysList = range(1, getDaysInMonth(new Date(currentUserProfile?.birthday)), 1);
  const daysNumbers = daysList.map((item, index) => {
    return {
      id: index,
      value: item.toFixed(0),
      day: `${item}`
    };
  });

  const monthsNames = useMemo(() => {
    let months = [];
    for (let i = 0; i < 12; i++) {
      months.push({
        id: i,
        value: i,
        month: `${enUS.localize.month(i)}`
      });
    }
    return months;
  }, []);

  const minimumYear = useMemo(() => {
    return getYear(
      sub(new Date(), {
        years: 100,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      })
    );
  }, []);

  const legalYears = useMemo(() => {
    return getYear(
      sub(new Date(), {
        years: 18,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      })
    );
  }, []);

  const yearsList = useMemo(() => {
    const yearRange = range(minimumYear, legalYears, 1).reverse();
    return yearRange.map((item, index) => {
      return {
        id: index,
        value: item,
        year: item
      };
    });
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onBlur', resolver: yupResolver(schema) });

  const getUserProfileData = useCallback(async () => {
    try {
      const response = await getUserProfile(userData?.profile?.id);
      dispatch(setProfile(response.data.data));
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, userData?.id]);

  useEffect(() => {
    getUserProfileData();
  }, [getUserProfileData]);

  const userGender = useMemo(() => {
    return genders.find((g) => g.value === currentUserProfile?.gender);
  }, [currentUserProfile?.gender, genders]);

  const userSeeking = useMemo(() => {
    return lookingFor.find((l) => l.value === currentUserProfile?.seeking);
  }, [currentUserProfile?.seeking, lookingFor]);

  const userBody = useMemo(() => {
    return bodyTypes.find((b) => b.value === currentUserProfile?.body_build);
  }, [currentUserProfile?.body_build, bodyTypes]);

  const userStatus = useMemo(() => {
    return civilStatus.find((c) => c.value === currentUserProfile?.civil_status);
  }, [civilStatus, currentUserProfile?.civil_status]);

  const userHair = useMemo(() => {
    return hairColors.find((h) => h.value === currentUserProfile?.hair_color);
  }, [currentUserProfile?.hair_color, hairColors]);

  const userEyesColor = useMemo(() => {
    return eyeColors.find((eye) => eye.value === currentUserProfile?.eye_color);
  }, [currentUserProfile?.eye_color, eyeColors]);

  const userInterest = useMemo(() => {
    if (typeof currentUserProfile.interests === 'string') {
      return {
        id: interests.find((interest) => interest.value === currentUserProfile.interests)?.id,
        label: currentUserProfile.interests,
        value: currentUserProfile.interests
      };
    } else {
      return currentUserProfile?.interests?.map((item) => {
        return {
          id: interests.find((interest) => interest.value === item)?.id,
          label: item,
          value: item
        };
      });
    }
  }, [currentUserProfile?.interests, interests]);

  const userHeight = useMemo(() => {
    return heightNumbers.find((height) => height.height === currentUserProfile?.height);
  }, [currentUserProfile?.height, heightNumbers]);

  const userDay = useMemo(() => {
    const dayOfBirth = getDate(new Date(currentUserProfile?.birthday));
    return {
      id: dayOfBirth,
      day: dayOfBirth,
      value: dayOfBirth
    };
  }, [currentUserProfile?.birthday, daysList]);

  const userMonth = useMemo(() => {
    const monthNumber = getMonth(new Date(currentUserProfile?.birthday));
    return {
      id: monthNumber,
      value: monthNumber,
      month: enUS.localize.month(monthNumber)
    };
  }, [currentUserProfile?.birthday]);

  const userYear = useMemo(() => {
    const yearNumber = getYear(new Date(currentUserProfile?.birthday));
    return {
      id: yearNumber,
      value: yearNumber,
      year: yearNumber
    };
  }, [currentUserProfile?.birthday]);

  const userBirthday = useMemo(() => {
    return birthday.length !== 0 ? birthday : currentUserProfile?.birthday?.split('T')[0];
  }, [birthday, currentUserProfile?.birthday]);

  const handleZipCodeChange = useCallback(
    async (e) => {
      const zipcode = e.target.value;
      if (zipcode.length !== 0) {
        try {
          const response = await getCityZipCode(zipcode);
          const { data } = response.data;
          setZipcodeCities(data);
          if (zipcode.length !== 0 && zipcode.length <= 5) {
            setZipcodeCities(data);
            document.getElementById('zipCodeCity').focus();
            document.getElementById('zipCode').focus();
          }
          if (data.length === 0 || zipcode.length > 5) {
            setZipcodeCities([]);
            setZipcodeCities([{ id: 0, city: 'No Cities Found', value: '' }]);
          }
        } catch (e) {
          console.log(e.errors);
          setZipcodeCities([]);
        }
      }
      if (zipcode.length === 0) {
        setZipcodeCities([{ id: 0, city: '', value: '' }]);
      }
    },
    [setValue]
  );

  const handleCityZip = useCallback(
    (e) => {
      const city = e.target.value;
      const zipcode = zipcodeCities.find((z) => z.city === city)?.zip_code;
      setValue('zip_code', zipcode);
      emptyError();
    },
    [setValue, zipcodeCities]
  );

  const sanitizeRequest = (data) => {
    for (let item in data) {
      if ((data[item] === null || data[item] === '') && item !== 'description') {
        delete data[item];
      }
    }
    return data;
  };

  const [errorZip, setErrorZip] = useState();

  const emptyError = () => {
    setErrorZip();
  };

  const onSubmit = useCallback(
    async (data) => {
      const heightValues = data.height.split('.');
      let profileData = {};
      if (data.height) {
        profileData = {
          ...data,
          birthday: userBirthday,
          height: { feet: heightValues[0], inches: heightValues[1] }
        };
      } else {
        profileData = {
          ...data,
          birthday: userBirthday
        };
      }

      const finalData = sanitizeRequest(profileData);
      mutate(
        { id: userData?.profile?.id, data: { ...finalData, ...{ version: 1 } } },
        {
          onSuccess: () => {
            localStorage.setItem('userData', JSON.stringify(userData));
            setShowSuccesMessage(true);
            scrollToTop();
          },
          onError: (e) => {
            setShowFailureMessage(true);
            setErrorZip(e.response.data.errors.zip_code);
            setErrorMessage(parseError(e));
          }
        }
      );
      /* try {
        await saveUserProfile(userData?.profile.id, finalData);
        userData.profile.city = finalData?.city;
        console.log(userData);
        //finalData?.city &&
        await localStorage.setItem('userData', JSON.stringify(userData));
        setShowSuccesMessage(true);
        scrollToTop();
      } catch (e) {
        setShowFailureMessage(true);
        console.log(e.response.data.errors.zip_code);
        setErrorZip(e.response.data.errors.zip_code);
        setErrorMessage(parseError(e));
        // scrollToTop();
      }*/
    },
    [userData?.id, userBirthday]
  );

  const handleChange = useCallback(
    (data) => {
      const interestsValues = data.map((item) => item.value);
      setValue('interests', interestsValues);
    },
    [setValue]
  );

  const handleBirthdayDay = useCallback(
    (e) => {
      const val = e.target.value;

      const newBirthDay = setDate(new Date(userBirthday), Number(val));
      setBirthday(format(newBirthDay, 'yyyy-MM-dd'));
    },
    [birthday, userBirthday]
  );

  const handleBirthdayMonth = useCallback(
    (e) => {
      const val = e.target.value;
      const newBirthDay = setMonth(new Date(userBirthday), Number(val));
      setBirthday(format(newBirthDay, 'yyyy-MM-dd'));
    },
    [birthday, userBirthday]
  );

  const handleBirthdayYear = useCallback(
    (e) => {
      const val = e.target.value;
      const newBirthDay = setYear(new Date(userBirthday), Number(val));
      setBirthday(format(newBirthDay, 'yyyy-MM-dd'));
    },
    [birthday, userBirthday]
  );

  const scrollToTop = () => {
    var elmnt = document.getElementById('scrollTop');
    elmnt.scrollIntoView();
  };

  return (
    <div className="Profile-Wrapper">
      <ProfileNav />
      {!loader ? (
        <div className="Profile-Info">
          <SuccessMessage
            className="Success-Message"
            message="PROFILE SETTINGS SUCCESSFULLY UPDATED"
            showMessage={showSuccesMessage}
            setShowMessage={setShowSuccesMessage}
          />
          <SuccessMessage
            className="Failure-Message"
            message={errorMessage}
            showMessage={showFailureMessage}
            setShowMessage={setShowFailureMessage}
          />
          <form onSubmit={handleSubmit(onSubmit)} className="Profile-Content">
            <div className="About-Me">
              <div>
                <p className="AboutMe Header">Username</p>
                <Input
                  register={register}
                  name={'username'}
                  defaultValue={currentUserProfile?.username}
                />
              </div>
              <div>
                <p className="AboutMe Header">About Me</p>
                <Tooltip
                  placement="topLeft"
                  title="Personal contact sharing is prohibited for privacy."
                >
                  <p className="Subheader">Describe yourself</p>
                </Tooltip>
                <textarea
                  {...register('description')}
                  defaultValue={currentUserProfile?.description}
                  maxLength={1024}
                  onInput={(e) => {
                    const regex = /[^a-zA-Z\s.,?!']/g; // Allows letters, spaces, and some punctuation
                    e.target.value = e.target.value.replace(regex, '');
                  }}
                />
              </div>
              <div className="General-Information">
                <p className="GeneralInfo Header">General Information</p>
                <div>
                  <p className="Subheader">Gender</p>
                  <Dropdown
                    register={register('gender')}
                    options={genders}
                    placeholder="Select"
                    name="gender"
                    valueName="value"
                    defaultselected={userGender}
                  />
                </div>
                <div>
                  <p className="Subheader">Birthdate</p>
                  <div className="Birthday-Dropdowns">
                    <Dropdown
                      options={daysNumbers}
                      name="day"
                      valueName="value"
                      defaultselected={userDay}
                      onChange={handleBirthdayDay}
                    />
                    <Dropdown
                      options={monthsNames}
                      name="month"
                      valueName="value"
                      defaultselected={userMonth}
                      onChange={handleBirthdayMonth}
                    />
                    <Dropdown
                      options={yearsList}
                      name="year"
                      valueName="value"
                      defaultselected={userYear}
                      onChange={handleBirthdayYear}
                    />
                  </div>
                </div>
                <div className="ZipCode-Wrapper">
                  <div className="ZipCode-Select">
                    <p className="Subheader">Zip Code</p>
                    <Input
                      type="number"
                      register={register}
                      name="zip_code"
                      defaultValue={currentUserProfile?.zip_code}
                      errorMessage={errors.zip_code?.message}
                      onChange={handleZipCodeChange}
                      onWheel={(e) => e.target.blur()}
                      id="zipCode"
                    />
                    {errorZip ? <div className="Invalid-Zip">Invalid zip code</div> : null}
                  </div>
                  <div>
                    <p className="Subheader">First Enter Zip Code</p>
                    <Dropdown
                      register={register('city')}
                      options={zipcodeCities}
                      placeholder={
                        zipcodeCities.length > 0 || !currentUserProfile?.city
                          ? 'Select City'
                          : currentUserProfile?.city
                      }
                      defaultValue={
                        zipcodeCities.length > 0 || !currentUserProfile?.city
                          ? 'Select City'
                          : currentUserProfile?.city
                      }
                      name="city"
                      valueName="city"
                      disabled={zipcodeCities.length === 0}
                      onChange={handleCityZip}
                      id="zipCodeCity"
                    />
                  </div>
                </div>
                <div className="Toggle-Options">
                  <div className="OnlineStatusSwitch">
                    <label>
                      <Switch
                        defaultChecked={Boolean(currentUserProfile?.show_online_status)}
                        register={register('show_online_status')}
                        onClick={(e) => toggleOnlineStatus(e)}
                      />
                      <span className="ToggleSpan">Show when I am online</span>
                    </label>
                  </div>
                  <div className="LocationSwitch">
                    <label>
                      <Switch
                        style={{ backgroundColorChecked: 'green' }}
                        register={register('private_location')}
                        onClick={(e) => togglePrivateLocation(e)}
                        defaultChecked={Boolean(currentUserProfile?.private_location)}
                      />
                      <span className="ToggleSpan">Keep my location private</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="Personal-Info">
              <p className="Header Personal-Information">Personal Information</p>
              <p className="Subheader">Looking for</p>
              <Dropdown
                register={register('seeking')}
                name="seeking"
                options={lookingFor}
                defaultselected={userSeeking}
                valueName="value"
                placeholder="Select"
              />
              <p className="Subheader">Height</p>
              <Dropdown
                register={register('height')}
                options={heightNumbers}
                placeholder="Select"
                name="height"
                valueName="value"
                defaultselected={userHeight}
              />

              <p className="Subheader"> Body Type </p>
              <Dropdown
                register={register('body_build')}
                options={bodyTypes}
                placeholder="Select"
                name="bodyType"
                valueName="value"
                defaultselected={userBody}
              />

              <p className="Subheader">Status</p>
              <Dropdown
                register={register('civil_status')}
                options={civilStatus}
                placeholder="Select"
                name="status"
                valueName="value"
                defaultselected={userStatus}
              />

              <p className="Subheader">Hair Colour</p>
              <Dropdown
                register={register('hair_color')}
                options={hairColors}
                placeholder="Select"
                name="hair"
                valueName="value"
                defaultselected={userHair}
              />

              <p className="Subheader">Eye Colour</p>
              <Dropdown
                register={register('eye_color')}
                options={eyeColors}
                placeholder="Select"
                name="eyes"
                valueName="value"
                defaultselected={userEyesColor}
              />
              <p className="Subheader">My Interests</p>
              <div className="Capitalize">
                <Select
                  register={register('interests')}
                  isMulti
                  placeholder="Select Option"
                  closeMenuOnSelect={false}
                  defaultselected={currentUserProfile?.interests}
                  defaultValue={userInterest}
                  options={interests}
                  onChange={handleChange}
                />
              </div>
              <button className="Button" type="submit">
                Save
              </button>
            </div>
            <div className="Tip">
              <div className="image-box">
                <img onContextMenu={(e) => e.preventDefault()} src={TIP} alt="tip" />
              </div>
              <p className="TipText">
                Amber’s Tip: Make sure to add as much information about yourself as possible, to
                help your matches, dates and local crushes get to know you better.
              </p>
            </div>
          </form>
        </div>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default Profile;
