import './style.scss';

import { Link, useNavigate, useParams } from '@reach/router';
import { Badge, notification, Skeleton, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaFlag } from 'react-icons/fa';
import { MdOutlineBlock } from 'react-icons/md';
import { useRecoilValue } from 'recoil';

import Button from '../../../components/Button/Button';
import { MEMBERS_ROUTES } from '../../../pages/MainView/components/MembersAreaMain/members_routes';
import { userData } from '../../../recoil/atoms/userDataAtom';
import { useGetProfile } from '../../services/profiles/useGetProfile';
import { GiftModal } from './GiftModal/GiftModal';
import { ImageCanvas } from './ImageCanvas';
import { InterestsList } from './InterestsList';

const { Title } = Typography;

export const ProfilePageMember: React.FC = () => {
  const params = useParams<{ userId?: string }>();
  const navigate = useNavigate();
  const user = useRecoilValue(userData);
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const view_from = searchParams.get('view_from');
  const viewFrom = view_from ? view_from : '';
  const { data, isLoading, error, status } = useGetProfile(params.userId, viewFrom);
  const profile = data?.data;

  const [giftModalOpen, setGiftModalOpen] = useState(false);

  const getAge = (): number => {
    const currentDate = new Date();
    const targetDate = new Date(profile?.birthday ?? ''); // Replace with your target date
    return currentDate.getFullYear() - targetDate.getFullYear();
  };

  useEffect(() => {
    if (data?.status === 404) {
      navigate(`${MEMBERS_ROUTES.MEMBERSAREA}?error=profile-not-found`);
    }
  }, [data, navigate]);

  const handleReportProfile = () =>
    notification.success({ message: 'User reported successfully!' });
  const handleBlockProfile = () => notification.success({ message: 'User blocked succesfully!' });

  return (
    <>
      <div className="profile-page">
        <div className="general">
          <div className="about">
            <Skeleton paragraph active loading={isLoading}>
              <div>
                <Title level={4}>About me</Title>
                <div dangerouslySetInnerHTML={{ __html: profile?.description ?? '' }} />
              </div>
            </Skeleton>
            <Skeleton paragraph active loading={isLoading}>
              <InterestsList interests={profile?.interests ?? []} />
            </Skeleton>
          </div>
          <div className="images-holder">
            {isLoading ? (
              <Skeleton.Image active style={{ height: 400 }} className="images-holder" />
            ) : (
              status === 'success' && (
                <ImageCanvas
                  favorite={profile?.is_favorite}
                  profile_image={profile?.profile_image}
                  gallery_images={profile?.gallery_images}
                  user={user}
                  id={profile?.id}
                />
              )
            )}
            <div className="buttons">
              <div className="username">
                {isLoading ? (
                  <Skeleton.Button active size="small" shape="square" />
                ) : (
                  <div className="content">
                    <div>
                      <span>{profile?.username}</span>{' '}
                      <Badge size="default" color={profile?.online_status ? 'green' : 'red'} />
                    </div>
                    <div className="controls">
                      <Tooltip title="Report">
                        <FaFlag className="icon" onClick={handleReportProfile}>
                          Report
                        </FaFlag>
                      </Tooltip>
                      <Tooltip title="Block">
                        <MdOutlineBlock className="icon" onClick={handleBlockProfile}>
                          Block
                        </MdOutlineBlock>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
              {isLoading ? (
                <>
                  <Skeleton.Button active block />
                  <Skeleton.Button active block />
                </>
              ) : (
                <>
                  <Button
                    onClick={() =>
                      navigate(
                        `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/${profile?.id}`
                      )
                    }
                    text="Send a message"
                  />
                  <Button onClick={() => setGiftModalOpen(true)} text="Send a gift" />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="fact-list">
          <Skeleton paragraph active loading={isLoading}>
            <Title level={4}>Profile facts</Title>
            <div>Age: {getAge()}</div>
            <div>
              City:{' '}
              <Link className="link" to={`/members-area/messagesnew/${profile?.id}`}>
                Ask for my city
              </Link>
            </div>
            <div>Height: {profile?.height}</div>
            <div>Body type: {profile?.body_build}</div>
            <div>Status: {profile?.civil_status}</div>
            <div>Hair color: {profile?.hair_color}</div>
            <div>Eye color: {profile?.eye_color}</div>
            <div>Want to know more?</div>
            <Link className="link" to={`/members-area/messagesnew/${profile?.id}`}>
              Ask me a question
            </Link>
          </Skeleton>
        </div>
      </div>
      {profile && (
        <GiftModal
          redirect="/members-area/messagesnew/"
          open={giftModalOpen}
          onCancel={() => setGiftModalOpen(false)}
          profileId={profile.id}
          profileUsername={profile.username}
        />
      )}
    </>
  );
};

ProfilePageMember.displayName = 'Profile';
