import './MatchNote.scss';

import { useNavigate } from '@reach/router';
import React from 'react';
import { useRecoilState } from 'recoil';

import itsMacth from '../../../assets/images/MatchGame/itsMatch.png';
import keepPlaying from '../../../assets/images/MatchGame/keepPlaying.svg';
import sendMessage from '../../../assets/images/MatchGame/sendMessage.svg';
import MaleAvatar from '../../../assets/images/MembersAreaNavbar/ProfileAvatar.svg';
import { MEMBERS_ROUTES } from '../../../pages/MainView/components/MembersAreaMain/members_routes';
import { userData as userDataAtom } from '../../../recoil/atoms/userDataAtom';
import ButtonWithIcon from '../../ButtonWithIcon/ButtonWithIcon';
import ImageN from '../../ImageN/ImageN';

const MatchNote = (props) => {
  const navigate = useNavigate();
  const { matchProfile, closeModal, modalRef, matchName } = props;
  const [userData] = useRecoilState(userDataAtom);
  const setAvatarPicture = () => {
    if (userData?.data.profile.profile_image) {
      return (
        <ImageN
          name={userData?.data.profile.profile_image}
          className="ProfilePhoto"
          classNameImg="ProfilePhoto"
        />
      );
    }
    return <img className="ProfilePhoto" src={MaleAvatar} alt="Profile-Avatar" />;
  };
  const sendMessageToMatch = () => {
    navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MESSAGESNEW}/${matchProfile?.id}`);
  };
  const keepPlayingGame = () => {
    closeModal(modalRef);
  };
  return (
    <div className="MatchNote">
      <img src={itsMacth} className="itsMatch" alt={'match'} />
      <p className="Subtitle">You and {matchName} liked each other!</p>
      <div className="ProfileWrapper">
        <div
          className="matchImageWrapper"
          onClick={() =>
            userData.data.my_profile_version === 1
              ? navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.PROFILE}`)
              : navigate(`${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MYPROFILE}`)
          }
        >
          {setAvatarPicture()}
        </div>
        <div
          className="matchImageWrapper"
          onClick={() =>
            navigate(
              `${MEMBERS_ROUTES.MEMBERSAREA}${MEMBERS_ROUTES.MEMBERPROFILE}/${matchProfile?.id}?view_from=match_game`
            )
          }
        >
          <img className="ProfilePhoto" src={matchProfile.public_avatar_url} alt="Profile-Avatar" />
        </div>
      </div>
      <ButtonWithIcon
        className="MatchButton"
        text={'send message'}
        iconSrc={sendMessage}
        onClick={sendMessageToMatch}
      />
      <ButtonWithIcon
        className="MatchButton1"
        text={'keep playing'}
        iconSrc={keepPlaying}
        onClick={keepPlayingGame}
      />
    </div>
  );
};
export default MatchNote;
