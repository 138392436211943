import { navigate } from '@reach/router';

import { ROUTES } from '../../pages/routes';

// Navigates to root page
export const showRootPage = (redirectUrl) => {
  return redirectUrl && redirectUrl !== 'undefined'
    ? navigate(`${ROUTES.ROOT}${redirectUrl}`)
    : navigate(ROUTES.ROOT);
};

// Navigates to about page
export const showAboutUsPage = () => {
  return navigate(ROUTES.ABOUT);
};

// Navigates to terms & condition page
export const showTermsAndConditionPage = () => {
  return navigate(ROUTES.TERMS);
};

// Navigates to privacy policy page
export const showPrivacyPolicyPage = () => {
  return navigate(ROUTES.PRIVACYPOLICY);
};

// Navigates to anti human traficking page
export const showAntiHumanTrafickingPage = () => {
  return navigate(ROUTES.ANTIHUMANTRAFICKING);
};

// Navigates to non consensual participation page
export const showNonConsensualParticipationPage = () => {
  return navigate(ROUTES.NONCONSENSUALPARTICIPATION);
};

// Navigates to FAQ page
export const showFAQPage = () => {
  return navigate(ROUTES.FAQ);
};

// Navigates to Pricing page
export const showPricingPage = () => {
  return navigate(ROUTES.PRICING);
};

// Navigates to Contact page
export const showContactPage = () => {
  return navigate(ROUTES.CONTACT);
};

//  Navigates to Email verification Instruction Page
export const showEmailVerificationInstruction = () => {
  return navigate(ROUTES.EMAILVERIFICATIONINSTRUCTION);
};

// Navigates to Members Area page
export const showMembersAreaPage = (redirectUrl) => {
  return redirectUrl && redirectUrl !== 'undefined'
    ? navigate(`${ROUTES.MEMBERSAREA}/${redirectUrl}`)
    : navigate(ROUTES.MEMBERSAREA);
};

export const showRedirectedPage = (redirectUrl) => {
  return redirectUrl && redirectUrl !== 'undefined'
    ? navigate(`${redirectUrl}`)
    : navigate(ROUTES.MEMBERSAREA);
};
