import './inboxConversation.scss';

import { Skeleton, Space } from 'antd';
import React from 'react';
import { BsInbox } from 'react-icons/bs';

import { ProfileCard } from '../../../../../../../../components/ProfileCard/ProfileCard';
import { ConversationHeader } from './ConversationHeader';

export const Description = ({ profile, isMobile, onClick, active }) => {
  return (
    <div className={'description'}>
      {isMobile && (
        <div className={'conv-header'}>
          <ConversationHeader profile={profile} isMobile={isMobile} onBack={onClick} />
        </div>
      )}
      {profile ? (
        <ProfileCard redirectFrom="inbox" profile={profile} disableSend />
      ) : (
        <Space direction={'vertical'} style={{ minWidth: '100%' }}>
          <Skeleton.Image active={active} />
          {active ? (
            <Skeleton paragraph={{ rows: 4 }} />
          ) : (
            <div className={'inbox-empty'}>
              <h2>No profile loaded</h2>
              <BsInbox size={60} color={'rgba(0,0,0,0.2)'} />
            </div>
          )}
        </Space>
      )}
      <div className={'desc-container'}>
        <div dangerouslySetInnerHTML={{ __html: profile?.description }} className={'desc-text'} />
      </div>
    </div>
  );
};
