import 'emoji-mart/css/emoji-mart.css';
import 'antd/dist/reset.css';
import './App.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { StrictMode, Suspense } from 'react';
import Div100vh from 'react-div-100vh';
import { RecoilRoot } from 'recoil';

import { useIdle } from './hooks/useIdle';
import MainView from './pages/MainView/MainView';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true
    }
  }
});

const App = () => {
  useIdle(1000 * 60 * 5);
  return (
    <StrictMode>
      <GoogleOAuthProvider
        clientId="49889973396-1mklb7sakkn7mkkjrd4h947hsfnnf0t8.apps.googleusercontent.com"
        ux_mode="redirect"
      >
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <Div100vh id="customDiv100ID">
              <div className="App">
                <Suspense fallback={<div className="SuspenseLoader">Loading...</div>}>
                  <MainView />
                </Suspense>
              </div>
            </Div100vh>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </RecoilRoot>
      </GoogleOAuthProvider>
    </StrictMode>
  );
};

export default App;
